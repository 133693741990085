import React, { useState, useEffect, useRef } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ThemeProvider, withTheme } from 'styled-components';
import { themes, ThemeProps } from '../../../shared';
import Card from '../../Cards/Card';
import Button from '../../Buttons/Button';
import Divider from '../../Divider';
import Loader from '../../Loader';
import {
  BackgroundOverlay,
  StyledCard,
  StyledFormGroup,
  StyledBlockquote,
  StyledIconButton,
} from './styled-components';
import { generateTimestamp, CheckViewport } from '../../../utils';

type PresetExportModalType = {
  inputCharacterCount: number;
  submitted?: boolean;
  hasSubmissionErrors?: boolean;
  showLicensedDataCheck: boolean;
  disableExtractModal?: boolean;
  setSubmitted: (arg0: boolean) => void;
  setHasSubmissionErrors: (arg0: boolean) => void;
  handleSubmit: ({ fileName, includeLicensedData }: { fileName: string; includeLicensedData: boolean }) => void;
} & ThemeProps &
  React.HTMLAttributes<HTMLDivElement> &
  React.CSSProperties;

const TERMS_AND_CONDITIONS = (
  <>
    I acknowledge that the use of this downloaded content is for internal planning purposes, internal operational
    purposes and <strong>specified</strong> external distribution purposes of your institution only. Some information
    may be in progress and subject to change. No unauthorized access, use, modification, duplication, distribution, or
    storage is permitted. I acknowledge that the terms of the Data Sharing and Services Agreement and applicable Product
    Use Sheets apply. The Institution assumes all risks and liabilities from the use of the Information. Where personal
    information appears on the record, I acknowledge that privacy obligations under the Municipal Freedom of Information
    and Protection of Privacy Act apply.
  </>
);

const PresetExportModal = ({
  inputCharacterCount,
  submitted,
  hasSubmissionErrors,
  showLicensedDataCheck,
  disableExtractModal,
  handleSubmit,
  setSubmitted,
  setHasSubmissionErrors,
  className,
  id,
  theme,
}: PresetExportModalType): JSX.Element => {
  const [showExtractModal, setShowExtractModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const [includeLicensedData, setIncludeLicensedData] = useState(true);
  const [timestamp, setTimestamp] = useState(generateTimestamp());
  const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState(false);
  const modalRef = useRef(null);
  const [notFullyVisibleInViewport, setNotFullyVisibleInViewport] = useState(false);
  const DEFAULT_MODAL_HEIGHT = 475;
  const [modalHeight, setModalHeight] = useState<number | string>('auto');

  useEffect(() => {
    if (showExtractModal && modalRef.current) {
      const viewportBound = new CheckViewport(modalRef.current);
      setNotFullyVisibleInViewport(viewportBound.out.bottom);
      if (viewportBound.out.bottom) {
        setModalHeight(
          DEFAULT_MODAL_HEIGHT -
            Math.abs((window.innerHeight || document.documentElement.clientHeight) - viewportBound.bounding.bottom),
        );
      } else {
        setModalHeight('auto');
      }
    }
  }, [showExtractModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showExtractModal) {
      setTimestamp(generateTimestamp());
      resetForm();
    }
  }, [showExtractModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSubmitting && submitted) {
      setIsSubmitting(false);
    }
  }, [submitted]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateFileName = (target: EventTarget & (HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement)) => {
    let err = '';

    // Validate for Special Characters, Only hyphen or space is allowed between characters
    const regex = /^(?=.{1,100}$)[a-zA-Z0-9]+(?:[-?| ?|_?][a-zA-Z0-9]+)*$/;
    if (target.value.length && !regex.test(target.value)) {
      err = 'Special characters are not allowed. Hyphen, underscore or space is allowed between characters.';
    }

    if (target.value.length >= inputCharacterCount) {
      err = `Report name must be less than ${inputCharacterCount} characters.`;
    }

    setError(err);
    setFileName(`${target.value}_${timestamp}`);
  };

  const resetForm = () => {
    setFileName('');
    setError('');
    agreedToTermsAndConditions && setAgreedToTermsAndConditions(false);
    !includeLicensedData && setIncludeLicensedData(true);
    isSubmitting && setIsSubmitting(false);
    submitted && setSubmitted(false);
    hasSubmissionErrors && setHasSubmissionErrors(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={`position-relative extract-modal ${className ? className : ''}`} id={id ? id : ''}>
        <StyledIconButton
          scale={false}
          outline={false}
          icon="fas fa-download"
          disabled={isSubmitting || disableExtractModal}
          onClick={() => !isSubmitting && !disableExtractModal && setShowExtractModal(!showExtractModal)}
          className="p-0 rounded-lg extract-modal__menu-button"
          tooltip={{ label: 'Export results' }}
        />
        {!disableExtractModal && showExtractModal ? (
          <>
            <BackgroundOverlay />
            <StyledCard
              className="extract-modal__card position-absolute"
              style={{
                overflowY: `${notFullyVisibleInViewport ? 'scroll' : 'hidden'}`,
                height: `${modalHeight !== 'auto' ? `${modalHeight}px` : modalHeight}`,
                width: '600px',
              }}
            >
              <div ref={modalRef}>
                <Button
                  id="cancel-button"
                  className="position-absolute extract-modal__card__cancel-button"
                  size="md"
                  disabled={isSubmitting}
                  onClick={() => setShowExtractModal(false)}
                >
                  <i className="fas fa-times" />
                </Button>
                <Card.Body>
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <StyledFormGroup>
                      <Form.Label htmlFor="fileName">What do you want to call this report?</Form.Label>
                      <div className="d-flex align-items-baseline">
                        <Form.Control
                          id="fileName"
                          name="fileName"
                          size="sm"
                          type="text"
                          autoComplete="off"
                          isInvalid={error !== ''}
                          disabled={isSubmitting || (submitted && !hasSubmissionErrors)}
                          maxLength={inputCharacterCount}
                          onChange={(e) => handleUpdateFileName(e.target)}
                          className="text-right w-100"
                        />
                        <small>{`_${timestamp}.xlsx`}</small>
                      </div>
                      <div className="invalid-feedback">{error}</div>
                    </StyledFormGroup>
                    {showLicensedDataCheck ? (
                      <>
                        <Divider className="my-3" />
                        <StyledFormGroup>
                          <Form.Label htmlFor="licence" className="d-flex align-items-center justify-content-between">
                            <div className="licensed-data">
                              <div>Do you want to include supplier data?</div>
                              <small className="detail-limit">(5,000 records max)</small>
                            </div>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="left"
                              overlay={
                                <Tooltip id="licensed-data-tooltip-extracts">
                                  <div className="text-left p-3">
                                    <p>
                                      Exports from search results over 5,000 records cannot contain supplier data (CVA,
                                      phase-in, sales information, legal description, Owner Names).
                                    </p>
                                    <p>MPAC results are limited to a maximum of 50,000.</p>
                                    <p className="mb-0">
                                      You can choose to refine your search, or choose between the first 5,000 supplier
                                      or all MPAC results.
                                    </p>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <Button>
                                <i className="fas fa-question-circle" />
                              </Button>
                            </OverlayTrigger>
                          </Form.Label>
                          <div>
                            <Form.Check
                              inline
                              disabled={isSubmitting || (submitted && !hasSubmissionErrors)}
                              label="Supplier Data"
                              id="licensedData"
                              type="radio"
                              checked={includeLicensedData}
                              onChange={() => setIncludeLicensedData(true)}
                            />
                            <Form.Check
                              inline
                              disabled={isSubmitting || (submitted && !hasSubmissionErrors)}
                              label="MPAC Data"
                              id="unlicensedData"
                              type="radio"
                              checked={!includeLicensedData}
                              onChange={() => setIncludeLicensedData(false)}
                            />
                          </div>
                        </StyledFormGroup>
                      </>
                    ) : null}
                  </Form>
                  <Divider className="my-3" />
                  {!submitted && !hasSubmissionErrors ? (
                    <Form.Check
                      className="small"
                      disabled={isSubmitting || submitted}
                      label={TERMS_AND_CONDITIONS}
                      id="acknowledgment"
                      type="checkbox"
                      onChange={() => setAgreedToTermsAndConditions(!agreedToTermsAndConditions)}
                      checked={agreedToTermsAndConditions}
                    />
                  ) : null}
                  {!isSubmitting && submitted ? (
                    <StyledBlockquote className="extract-modal__alert m-0" $hasError={hasSubmissionErrors}>
                      {!hasSubmissionErrors ? (
                        <>
                          <p className="mx-3">Export queued. You will receive a notification when completed.</p>
                          <div className="mx-3 mb-1 d-flex align-items-center">
                            <i className="fas fa-bell text-muted" />
                            <small className="text-muted ml-2">
                              Use the bell icon at the right hand corner of the dashboard to view your export history.
                            </small>
                          </div>
                        </>
                      ) : (
                        <p className="mx-3 mb-0">Oops! Something went wrong.</p>
                      )}
                    </StyledBlockquote>
                  ) : null}
                  <div className="text-right extract-modal__card__submit-button mt-3">
                    {!submitted && !isSubmitting ? (
                      <Button
                        size="sm"
                        id="submit-button"
                        type="submit"
                        variation="success"
                        disabled={!agreedToTermsAndConditions || isSubmitting || fileName === '' || error !== ''}
                        onClick={() => {
                          handleSubmit({ fileName, includeLicensedData });
                          setIsSubmitting(true);
                        }}
                      >
                        <div>
                          <span className="mr-2">Initiate Download</span>
                          <i className="fas fa-file-export" />
                        </div>
                      </Button>
                    ) : null}

                    {isSubmitting ? (
                      <Button size="sm" id="isSubmitting-button" variation="success" disabled>
                        <div className="d-flex align-items-center justify-content-between">
                          <span className="mr-2">Submitting</span>
                          <Loader size="xs" className="p-0" variation="basic" />
                        </div>
                      </Button>
                    ) : null}

                    {submitted && !isSubmitting && !hasSubmissionErrors ? (
                      <Button
                        size="sm"
                        id="submit-button-done"
                        type="submit"
                        variation="success"
                        onClick={() => {
                          setShowExtractModal(false);
                          resetForm();
                        }}
                      >
                        <div>
                          <span className="mr-2">Done!</span>
                          <i className="fas fa-check" />
                        </div>
                      </Button>
                    ) : null}

                    {submitted && !isSubmitting && hasSubmissionErrors ? (
                      <Button
                        size="sm"
                        id="submit-button-try-again"
                        type="submit"
                        variation="warning"
                        onClick={() => {
                          handleSubmit({ fileName, includeLicensedData });
                          setIsSubmitting(true);
                        }}
                      >
                        <div>
                          <span className="mr-2">Try Again</span>
                          <i className="fas fa-redo" />
                        </div>
                      </Button>
                    ) : null}
                  </div>
                </Card.Body>
              </div>
            </StyledCard>
          </>
        ) : null}
      </div>
    </ThemeProvider>
  );
};

PresetExportModal.defaultProps = {
  theme: themes.pallets.mpac, // eslint-disable-line react/default-props-match-prop-types
  variation: 'primary', // eslint-disable-line react/default-props-match-prop-types,
  inputCharacterCount: 50,
  showLicensedDataCheck: false,
  disableExtractModal: false,
};

export default withTheme(PresetExportModal);
